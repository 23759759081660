import { default as indexmvCpaim320Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/index.vue?macro=true";
import { default as loginuvXCe7C4hTMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/login.vue?macro=true";
import { default as _91id_93slxzOO8OvPMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/orders/[id].vue?macro=true";
import { default as createiJTlDtlXNXMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/orders/create.vue?macro=true";
import { default as indexApsu5u2c8fMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/orders/index.vue?macro=true";
import { default as print_45jobsCrCnQ6FoGRMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/print-jobs.vue?macro=true";
import { default as indexl59Kx3VuuqMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/reservations/index.vue?macro=true";
import { default as lanesOdngq31a8uMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/reservations/lanes.vue?macro=true";
import { default as settlementR2orxTVyYSMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/settlement.vue?macro=true";
import { default as setupM9LSoihmPXMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/setup.vue?macro=true";
import { default as editkp8yCg1h4JMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock-statuses/[id]/edit.vue?macro=true";
import { default as indexo1dWoDnR1GMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock-statuses/[id]/index.vue?macro=true";
import { default as createccqxwS2lFyMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock-statuses/create.vue?macro=true";
import { default as indexu7uxRWESMTMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock-statuses/index.vue?macro=true";
import { default as stock_45statuseskf1LgxAniNMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock-statuses.vue?macro=true";
import { default as indexNXbMd6wt9TMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock/index.vue?macro=true";
import { default as createAes6LaHVBsMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock/statuses/create.vue?macro=true";
import { default as createlcA4PSkNRyMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock/transfers/create.vue?macro=true";
import { default as indexbuBDvyIGVZMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/tables/[tableId]/orders/[orderId]/index.vue?macro=true";
import { default as split2WRFZ41K0AMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/tables/[tableId]/orders/[orderId]/split.vue?macro=true";
import { default as _91orderId_93sj31FX44dkMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/tables/[tableId]/orders/[orderId].vue?macro=true";
import { default as createYtP7K2C82bMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/tables/[tableId]/orders/create.vue?macro=true";
import { default as _91tableId_93HgACSf6aNSMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/tables/[tableId].vue?macro=true";
import { default as indexDZ7gh8T5q0Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/tables/index.vue?macro=true";
import { default as indexLOx0QSJOovMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/takeaway-orders/[id]/index.vue?macro=true";
import { default as split24jdqVo8N2Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/takeaway-orders/[id]/split.vue?macro=true";
import { default as _91id_93BEe0bmd8DKMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/takeaway-orders/[id].vue?macro=true";
import { default as createZDlPKZKpdQMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/takeaway-orders/create.vue?macro=true";
import { default as indexi7iIzxhVCtMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/takeaway-orders/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginuvXCe7C4hTMeta || {},
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders-create",
    path: "/orders/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "print-jobs",
    path: "/print-jobs",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/print-jobs.vue").then(m => m.default || m)
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: "reservations-lanes",
    path: "/reservations/lanes",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/reservations/lanes.vue").then(m => m.default || m)
  },
  {
    name: "settlement",
    path: "/settlement",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/settlement.vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    meta: setupM9LSoihmPXMeta || {},
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: stock_45statuseskf1LgxAniNMeta?.name,
    path: "/stock-statuses",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock-statuses.vue").then(m => m.default || m),
    children: [
  {
    name: "stock-statuses-id-edit",
    path: ":id()/edit",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock-statuses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-id",
    path: ":id()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock-statuses/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock-statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses",
    path: "",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock-statuses/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "stock",
    path: "/stock",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "/stock/statuses/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock/statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-transfers-create",
    path: "/stock/transfers/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/stock/transfers/create.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId",
    path: "/tables/:tableId()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/tables/[tableId].vue").then(m => m.default || m),
    children: [
  {
    name: _91orderId_93sj31FX44dkMeta?.name,
    path: "orders/:orderId()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/tables/[tableId]/orders/[orderId].vue").then(m => m.default || m),
    children: [
  {
    name: "tables-tableId-orders-orderId",
    path: "",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/tables/[tableId]/orders/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId-orders-orderId-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/tables/[tableId]/orders/[orderId]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables-tableId-orders-create",
    path: "orders/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/tables/[tableId]/orders/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables",
    path: "/tables",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/tables/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93BEe0bmd8DKMeta?.name,
    path: "/takeaway-orders/:id()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/takeaway-orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "takeaway-orders-id",
    path: "",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/takeaway-orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders-id-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/takeaway-orders/[id]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "takeaway-orders-create",
    path: "/takeaway-orders/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/takeaway-orders/create.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders",
    path: "/takeaway-orders",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20241213091522/pages/takeaway-orders/index.vue").then(m => m.default || m)
  }
]